import { CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import qs from "qs";
import { localstorageKeys } from '../../services/local-storage-keys';
import { useRecoilState, } from 'recoil';
import { surveyGenerationState } from '../../store/survey-generation';

const SurveyGenerationPage: React.FC = () => {

  const [surveyGeneration, setSurveyGeneration] = useRecoilState(surveyGenerationState);
    
     const queryParams = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });

      useEffect(() => {
        const currentUrl = new URL(window.location.href); // Get the full current URL
        const params = new URLSearchParams(currentUrl.search); // Extract query params
    
        // Add a new parameter `userId`
        params.set('userId', surveyGeneration?.id); // Replace '12345' with the desired userId value
    
        const newBaseUrl = `https://public-pages.winfi.co/surveys/${queryParams?.surveyId}`; // New base URL
        const newUrl = `${newBaseUrl}?${params.toString()}`; // Construct new URL with params
    
        // Redirect to the new URL
        window.location.href = newUrl;
      }, []);

return (
    <div
        style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", 
            height: "100vh" , marginTop: "2rem" }}
    >
        <CircularProgress color="inherit" size={22} />
        <span
        style={{
            marginLeft: "12px",
            fontSize: "1.5rem",
            fontWeight: "500",
        }}
        >
        Loading...
        </span>
    </div>
)}

export default SurveyGenerationPage;
import Routes from "./routes";
import "./i18n";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { Alert, Box, LinearProgress, Snackbar } from "@mui/material";
import { Notification, notificationsState } from "./store/notifications";
import { LoadingState } from "./store/global-loading";
import { useRecoilState, useRecoilValue } from "recoil";
import { Suspense } from "react";
import { PublicRoute } from "./components/PublicRoute";
import InitalPage from "./views/processing";
import DisabledService from "./views/disabledService";
import { serviceState } from "./store/service";
import SurveyGenerationPage from "./views/survey/generate";

function App() {
  const loading = useRecoilValue(LoadingState);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [service] = useRecoilState(serviceState);

  const handleClose = (index: number, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    let copyOfNotifications = [...notifications];
    copyOfNotifications.splice(index, 1);
    setNotifications(copyOfNotifications);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {loading && <LinearProgress />}

      {service === "enabled" ? (
        <Routes />
      ) : (
        <Router>
          <Suspense
            fallback={
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            }
          >
            <Switch>
              <PublicRoute exact={true} path="/">
                <InitalPage />
              </PublicRoute>

              <PublicRoute exact={true} path="/survey/generate">
                <SurveyGenerationPage />
              </PublicRoute>

              <PublicRoute exact={true} path="/service/disabled">
                <DisabledService />
              </PublicRoute>

              <Redirect from="*" to={"/"} />
            </Switch>
          </Suspense>
        </Router>
      )}

      {notifications &&
        notifications.length > 0 &&
        notifications.map((notification: Notification, i: number) => (
          <Snackbar
            key={i}
            open={true}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={(e, r) => handleClose(i, r)}
            sx={{
              backgroundColor: "transparent",
            }}
          >
            <Alert
              onClose={() => handleClose(i)}
              severity={notification.type}
              sx={{ width: "100%" }}
              variant="filled"
            >
              {notification.message}
            </Alert>
          </Snackbar>
        ))}
    </Box>
  );
}

export default App;

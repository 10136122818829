export const localstorageKeys = {
  token: "token",
  refreshToken: "refreshToken",
  user: "user",
  device: "device",
  companyStatus: "companyStatus",
  theme: "theme",
  mac: "mac",
  service: "service",
  branchId: "branchId",
  loginLink: "loginLink",
  resendTimes: "resendTimes",
  bookingInfo: "bookingInfo",
  companyFeatures:"companyFeatures",
  surveyGeneration: "surveyGeneration"
};

import { atom } from "recoil";
import { localstorageKeys } from "../services/local-storage-keys";

export const surveyGenerationState = atom({
  key: "surveyGeneration",
  default: JSON.parse(
    sessionStorage.getItem(localstorageKeys.surveyGeneration) ?? localStorage.getItem(localstorageKeys.surveyGeneration) ?? "{}"
  ) as any,
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((surveyGeneration) => {
        sessionStorage.setItem(localstorageKeys.surveyGeneration, JSON.stringify(surveyGeneration));
        localStorage.setItem(localstorageKeys.surveyGeneration, JSON.stringify(surveyGeneration));
      });
    },
  ],
});

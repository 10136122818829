import { lazy } from "react";

export const publicRoutes = [
  {
    path: "/",
    Component: lazy(() => import("../views/processing")),
    exact: true,
  },
  {
    path: "/service/connect",
    Component: lazy(() => import("../views/connect")),
    exact: true,
  },
  {
    path: "/auth/login",
    Component: lazy(() => import("../views/auth/login")),
    exact: true,
  },
  {
    path: "/auth/login/verify",
    Component: lazy(() => import("../views/auth/login/verify")),
    exact: true,
  },
  {
    path: "/auth/register",
    Component: lazy(() => import("../views/auth/register")),
    exact: true,
  },
  {
    path: "/service/disabled",
    Component: lazy(() => import("../views/disabledService")),
    exact: true,
  },
  {
    path: "/service/connect/error",
    Component: lazy(() => import("../views/connectionError")),
    exact: true,
  },
  {
    path: "/terms",
    Component: lazy(() => import("../views/terms")),
    exact: true,
  },
  {
    path: "/privacy",
    Component: lazy(() => import("../views/privacy")),
    exact: true,
  },
  {
    path: "/survey/generate",
    Component: lazy(() => import("../views/survey/generate")),
    exact: true,
  },
];

export const privateRoutes = [];
